@use '../../styles/constants/colors';
@use '../../styles/constants/fonts';

// background-color:colors.$RED;
// color: colors.$WHITE2;
// font-family: fonts.$NANUMS_R;
.companyHeader {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 11.7rem;
  font-size: 6rem;
  font-family: fonts.$NANUMS_R;

  > p {
    font-size: 1.8rem;
    margin-top: 4.8rem;
  }
}
.companySection {
  // display: flex;
  // align-items: center;
  // justify-content: center;

  max-width: 1200px;
  margin: 0 auto;
  margin-top: 4.8rem;

  h1 {
    font-size: 6rem;
  }

  .companyMenu {
    display: flex;
    // margin: 4.2rem 0;
    margin-bottom: 4.2rem;
    li button {
      font-family: fonts.$NANUMS_L;
      text-align: center;
      background: none;
      font-size: 1.8rem;
      cursor: pointer;
      width: 18.3rem;
      padding: 4% 5%;
      border-radius: 3px;
      // margin-right: 7rem;
      margin-right: 4rem;
    }
    .active {
      background-color: colors.$RED;
      color: colors.$WHITE;
    }
  }

  .companyBox {
    background-color: colors.$WHITE;
    // padding: 5% 3%;
    padding: 11% 3%;
    font-size: 3rem;
    font-family: fonts.$NANUMS_L;
    border-radius: 6px;
    .servicesIntro {
      > ul {
        display: flex;
        font-family: fonts.$NANUMS_L;
        font-size: 2.3rem;
        // justify-content: space-between;
        // justify-content: space-evenly;
        flex-wrap: wrap;
        // gap: 6rem;
        li:not(:last-child) {
          // margin-right: 3rem;
        }
      }
      // .addBox{
      //   margin-top: 5%;
      // }
      > ul > li {
        // width: 30%;

        > p {
          margin: 3rem 0;
          margin-top: 0;
          font-family: fonts.$NANUMS_L;
          .contentsRed {
            color: colors.$RED;
          }
        }
        .contents {
          font-size: 2rem;
          font-family: fonts.$NANUMS_L;
        }
        pre {
          white-space: pre-wrap;
          // font-size: 1.5rem;
          font-size: 2.5rem;
          line-height: 2rem;
          color: colors.$GRAY8;
        }
      }
      > ul > li:last-child {
        // margin-top: 4rem;
      }
      > ul > li {
      }
      .servicesImg {
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .companyHeader {
    h1 {
      font-size: 20rem;
      padding: 10rem 6rem;
    }
    p {
      font-size: 8rem;
      line-height: 10rem;
      // padding: 7rem 6rem;
      padding: 0 6rem;
      margin-top: 0;
    }
  }
  .companySection {
    .companyMenu {
      // margin: 7.2rem 6rem;
      margin: 10rem 6rem;
      align-items: center;

      li button {
        width: 100%;
        font-size: 7rem;
      }
      .active {
        background-color: colors.$RED;
        color: colors.$WHITE;
      }
    }
    .companyBox {
      border-radius: 0;
      .servicesIntro {
        > ul {
          flex-direction: column;
          > li {
            width: 100%;
            > p {
              font-size: 8rem !important;
              line-height: 1.3;
              margin: 9rem 0;
            }
            .contents {
              font-size: 6rem !important;
            }

            > pre {
              font-size: 6rem;
              line-height: 1.3;
            }
          }
          > li:not(:last-child) {
            margin-bottom: 15rem;
          }
        }
        .addBox {
          // margin-top: 3rem;
        }
      }
    }
    .companyMenu {
      > li {
        width: 28%;
      }
    }
  }
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    height: auto;
  }
}
