@use '../../styles/constants/colors';
@use '../../styles/constants/fonts';

.navbar {
  top: 0;
  z-index: 9999;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
  background: #fafafa;
  border-top: 0.4rem solid colors.$BLACK;
  border-bottom: 1px solid #dbdbdb;
}

.fix {
  position: fixed !important;
}

.navbar a {
  float: left;
  padding: 1rem;
  font-size: 1.6rem;
  color: colors.$WHITE;
  text-align: center;
  text-decoration: none;
}

.dropdown {
  // display: flex;
  // justify-content: space-between;
  width: 100%;
  max-width: 1400px;
  height: 6.6rem;
  padding: 0 5%;
  margin: 0 auto;
  overflow: hidden;
  font-family: fonts.$NANUMS_R;
  font-weight: normal;
  text-align: center;
}

.dropdown .dropbtn {
  width: 16.66%;
  height: inherit;
  margin: 0;
  font-family: fonts.$NANUMS_R;
  font-size: 1.8rem;
  font-weight: normal;
  color: #202721;
  letter-spacing: -0.88px;
  background-color: inherit;
  border: none;
  outline: none;
}

.lawyer_nav {
  width: 12.5% !important;
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  left: 0;
  z-index: 1;
  display: none;
  width: 100%;
  background-color: #000000cc;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 20%);
}

.lawyer_dropdown-content {
  height: inherit !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Create three equal columns that floats next to each other */
.column {
  // float: left;
  width: 16.66%;
  // height: 270px;
  padding: 1rem 0;
}

.column .clientBriefCounsel {
  cursor: pointer;
}

.column a,
.column button {
  display: block;
  float: none;
  padding: 1.6rem 0;
  margin: 0 auto;
  font-size: 1.7rem;
  color: #898989;
  text-align: center;
  text-decoration: none;
}

.column a:hover,
.column button:hover {
  color: colors.$WHITE;
}

.row {
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
  padding: 0 5%;
  margin: 0 auto;
}

.lawyer_low {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: inherit !important;
}

/* Clear floats after the columns */
.row::after {
  display: table;
  clear: both;
  content: '';
}
@media screen and (max-width: 425px) {
  .mobileOff {
    display: none;
  }

  .main {
    // margin-top: 25rem;
  }
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    height: auto;
  }
}
