@use '../../styles/constants/colors';
@use '../../styles/constants/fonts';

// background-color:colors.$RED;
// color: colors.$WHITE2;
// font-family: fonts.$NANUMS_R;
.companySection {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 11.7rem;

  h1 {
    font-size: 6rem;
    font-family: fonts.$NANUMS_R;
  }

  .companyMenu {
    display: flex;

    margin: 4.2rem 0;
    li button {
      background: none;
      font-size: 1.8rem;
      cursor: pointer;
      width: 17.3rem;
      text-align: center;
      padding: 4% 5%;
      border-radius: 3px;
      margin-right: 7rem;
      font-family: fonts.$NANUMS_L;
    }
    .active {
      background-color: colors.$RED;
      color: colors.$WHITE;
    }
  }

  .companyBox {
    background-color: colors.$WHITE;
    padding: 5% 3%;
    font-size: 3rem;
    font-family: fonts.$NANUMS_L;
    border-radius: 6px;
    ////////////////////////////////////////// 소개
    .companyIntro {
      font-size: 1.7rem;
      font-family: fonts.$NANUMS_L;
      line-height: 1.59;
      letter-spacing: -1.28px;
      display: flex;
      > div {
        width: 50%;
      }

      .contentsLeft {
        margin-right: 1.5rem;
        pre:first-child {
          margin-bottom: 3rem;
        }

        pre {
          font-size: 1.7rem;
          font-family: fonts.$NANUMS_L;
          line-height: 1.59;
          letter-spacing: -1.28px;
          word-break: break-all;
          white-space: pre-wrap;
        }

        .briefRequestContent {
          max-width: 1400px;
          padding: 2.1rem 0;
          margin: 0 auto;

          h1 {
            margin-bottom: 2.1rem;
            font-family: fonts.$NANUMS_R;
            // font-size: 3rem;
            font-size: 2.5rem;
            text-align: left;
            font-weight: bold;
            letter-spacing: -0.88px;
          }

          .flexColumn {
            display: flex;
            flex-direction: column;
          }

          .flexRow {
            display: flex;
            flex-direction: row;
          }

          .ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .tableWrapper {
            font-size: 1.5rem;

            > dl > div {
              border-bottom: 1px solid colors.$BORDER_GRAY;

              dt,
              dd {
                padding: 1.3rem 1rem;
                letter-spacing: -0.63px;
                font-family: fonts.$NANUMS_L;
              }

              dt {
                width: 15%;
                // width: 30%;
              }

              dd {
                width: 85%;
                // color: colors.$GRAY8;
              }
            }
            .tableHeaderWrapper {
              // background-color: colors.$TABLE_HEADER_GRAY;
              border-top: 3px solid colors.$BLACK;
              border-bottom: 1px solid colors.$BORDER_GRAY;
            }
          }
        }
      }
      .contentsRight {
        margin-left: 1.5rem;
        .contentsRightImg {
          width: 20%;
        }
        p {
          font-size: 1.4rem;
          font-family: fonts.$NANUMS_L;
          span {
            font-weight: bold;
          }
          .contentsGreen {
            color: #80bb2b;
          }
          .contentsRed {
            color: #ffb400;
          }
        }

        img {
          object-fit: cover;
          border-radius: 10px;
          width: 100%;
          margin-bottom: 2.9rem;
        }
      }
    }
  }
  ////////////////////////////////////////// 뉴스

  .noticeListWrapper {
    margin-bottom: 5%;
    > li {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      h1 {
        margin-bottom: 2.1rem;
        font-family: fonts.$LIGHT;
        font-size: 3rem;
        text-align: left;
        letter-spacing: -0.1rem;
      }
    }

    .flexColumn {
      display: flex;
      flex-direction: column;
    }

    .flexRow {
      display: flex;
      flex-direction: row;
    }

    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .underLineNone {
      text-decoration: none !important;
      cursor: default !important;
    }

    .tableWrapper {
      .tableHeaderWrapper {
        background-color: colors.$TABLE_HEADER_GRAY;
        border-top: 3px solid colors.$BLACK;
        border-bottom: 1px solid colors.$BORDER_GRAY;

        > div > div {
          font-family: fonts.$NANUM;
        }
      }

      .rowWrapper {
        border-bottom: 1px solid colors.$BORDER_GRAY;

        .titleBox {
          font-family: fonts.$LIGHT;
          font-size: 1.8rem;
          line-height: 1.52;
        }
      }

      .infoBox {
        align-items: center;
        width: 100%;
        font-size: 1.8rem;

        > div {
          padding: 2rem 1rem;
          // font-family: fonts.$LIGHT;
          font-family: fonts.$NANUMS_L;
          text-align: center;
        }

        .serviceType {
          width: 5%;
        }

        .caseType {
          width: 15%;
        }

        .date {
          width: 15%;
        }

        .no {
          width: 10%;
        }

        .title {
          width: 75%;

          &:hover {
            text-decoration: underline;
            cursor: pointer !important;
          }
        }

        .suggestionCount {
          width: 12%;
          padding: 0;
        }

        .bar {
          display: none;
        }

        .textStart {
          text-align: start;
        }

        .buttonWrapper {
          display: flex;
          align-items: center;
          width: 17%;
          min-width: fit-content;

          > button {
            line-height: 8rem;
          }
        }
      }

      .empty {
        display: flex;
        justify-content: center;
        font-size: 2rem;
        line-height: 6rem;
        border-bottom: 1px solid #dbdbdb;
      }
    }
  }

  ////////////////////////////////////////// 입사지원

  .partnershipInquiry {
    h1 {
      margin-bottom: 2.1rem;
      font-family: fonts.$LIGHT;
      font-size: 2.5rem;
      text-align: left;
      letter-spacing: -0.88px;
    }

    .row_table {
      font-size: 1.8rem;

      > :first-child {
        // background-color: colors.$TABLE_HEADER_GRAY;
        border-top: 0.3rem solid colors.$BLACK;
      }

      > :nth-child(4) {
        padding: 1.1rem 1.75rem;
      }

      > :nth-child(4),
      :nth-child(8) {
        display: flex;
        align-items: center;
      }

      .addInput {
        width: 73%;
        margin-right: 1%;
      }

      .summaryDownload {
        > span {
          > div {
            > button {
              all: unset !important;
              width: 12.5% !important;
              padding: 0.7rem 0 !important;
              font-size: 1.8rem !important;
              color: colors.$WHITE !important;
              text-align: center !important;
              letter-spacing: -0.63px !important;
              cursor: pointer !important;
              background-color: colors.$BLACK !important;
            }
          }
        }
      }

      li {
        height: auto;
        padding: 1.75rem;
        border-bottom: 1px solid #dbdbdb;

        > span {
          > input {
            width: 96%;
            height: inherit;
          }
        }

        textarea {
          width: 96%;
          height: 24.5rem;
          line-height: 1.5;
        }
      }

      .input_li {
        padding: 1.2rem 0 1.2rem 1.75rem;
        display: flex;
        align-items: center;

        > .label_middle {
          vertical-align: middle;
        }

        > .span_short_width {
          display: inline;

          > span {
            width: fit-content;
            padding: 0 1%;
          }

          > input {
            width: 30.92%;
          }

          .date {
            width: 16.85%;
          }

          .cd {
            height: 2rem;
          }
        }

        > .account {
          display: inline-block;
          width: 90%;

          > :first-child {
            width: 10%;
            margin-right: 1%;
          }

          > :nth-child(2) {
            width: 19.8%;
          }

          :last-child {
            width: 50%;
            padding: 0 1%;
            font-size: 1.5rem;
            color: colors.$GRAY6;
            letter-spacing: -0.52px;
          }
        }

        .selectors {
          width: 11%;
          height: 5rem;
          padding: 0 2rem;
          margin-right: 1rem;
          font-size: 1.7rem;
          background: url('../../assets/images/arrow.png')
            no-repeat 95% 50%;
          // color: colors.$GRAYA;
          background-color: #f4f4f4;
          border: none;
          appearance: none;
        }

        .selectorsApply {
          width: 18%;
        }
      }

      label {
        display: inline-block;
        width: 10%;
        letter-spacing: -0.63px;
        vertical-align: top;
      }

      span {
        display: inline-block;
        width: 90%;

        > div > :first-child {
          margin-right: 0.6rem;
        }

        > div > button {
          all: unset;
          width: 12.5%;
          padding: 0.7rem 0;
          font-size: 1.8rem;
          color: colors.$WHITE;
          text-align: center;
          letter-spacing: -0.63px;
          cursor: pointer;
          background-color: #413931;
        }
      }

      .bank {
        width: 10%;
      }

      .orange_text {
        color: colors.$ORANGE;
      }

      .bold {
        font-weight: bold;
      }
    }

    .text_counter {
      padding: 1.7rem 0;
    }

    .title_space {
      margin-top: 1rem;
    }

    .suggestion_content {
      line-height: 1.72;
      letter-spacing: -0.63px;
    }

    .middleInput {
      width: 78% !important;

      input {
        width: 95%;
      }
    }

    .addFileBtn {
      all: unset;
      width: 12% !important;
      // padding: 0.85rem 0;
      padding: 1.2rem 0;
      font-size: 1.8rem;
      color: colors.$WHITE;
      text-align: center;
      cursor: pointer;
      background-color: #413931;
    }

    .buttonWrapper {
      display: flex;
      justify-content: end;
      // padding: 6rem 0;
      margin-top: 8rem;
      cursor: pointer;
    }

    .listBtn {
      all: unset;
      width: 12%;
      padding: 1.25rem 0;
      font-size: 1.8rem;
      color: colors.$WHITE;
      text-align: center;
      // background-color: #413931;
      background-color: colors.$RED;
    }
  }
}

@media screen and (max-width: 425px) {
  .companySection {
    // margin-top: 24.7rem;
    > h1 {
      font-size: 20rem;
      // padding: 7rem 6rem;
      padding: 10rem 6rem;
    }
    .companyMenu {
      margin: 7.2rem 6rem;
      margin-top: 0;
      align-items: center;
      li button {
        width: 100%;
        font-size: 7rem;
      }
      .active {
        background-color: colors.$RED;
        color: colors.$WHITE;
      }
    }
    .companyBox {
      border-radius: 0;
      .companyIntro {
        flex-direction: column;
        > div {
          width: 100%;
        }
        .contentsLeft {
          pre {
            font-size: 6rem;
            line-height: 1.3;
          }
          .briefRequestContent {
            padding: 5% 0;
            > h1 {
              font-size: 8rem;
              margin-bottom: 3%;
            }

            .tableWrapper {
              font-size: 6rem;

              > dl > div {
                dt {
                  width: 20%;
                }
              }
            }
          }
        }
        .contentsRight {
          p {
            font-size: 6.4rem;
          }
          .contentsLogo {
            display: flex;
            justify-content: center;
            margin-bottom: 5%;
          }
        }
      }
    }

    .briefRequestContent {
      > li {
        padding: 3rem 0;

        h1 {
          margin-bottom: 2.1rem;
          font-family: fonts.$LIGHT;
          font-size: 9.85rem;
          line-height: 12.5rem;
          text-align: left;
        }
      }

      .tableWrapper {
        font-size: 7rem;

        > dl > div {
          dt,
          dd {
            padding: 6rem 0;
          }

          dt {
            width: 30%;
          }

          dd {
            width: 58%;
            color: colors.$GRAY8;
          }
        }

        .buttonWrapper {
          width: 60%;

          > button {
            height: 12rem;
            font-size: 5.5rem;
            line-height: 1.8rem;
            color: colors.$WHITE;
            text-align: center;
          }
        }
      }

      .loanRequest {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10rem 0;

        p {
          font-size: 7rem;
        }

        button {
          all: unset;
          width: 90%;
          margin: 5rem 0;
          font-size: 7rem;
          line-height: 18rem;
          color: colors.$WHITE;
          text-align: center;
          background-color: colors.$RED;
        }
      }
    }

    .noticeListWrapper {
      > li {
        padding: 3rem 0;
        border-bottom: 1.1rem solid black;

        h1 {
          font-size: 9.85rem;
          line-height: 12.5rem;
          letter-spacing: -0.345rem;
        }
      }

      .flexColumn {
        display: flex;
        flex-direction: column;
      }

      .flexRow {
        display: flex;
        flex-direction: row;
      }

      .ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .tableWrapper {
        .tableHeaderWrapper {
          display: none;
        }
        .rowWrapper {
          padding: 6rem 0;
          border-bottom: 1px solid colors.$BORDER_GRAY;
        }

        .infoBox {
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          padding: 6rem 0 3rem 3rem;
          font-size: 6rem;
          flex-direction: column;
          font-size: 7rem !important;
          align-items: start !important;
          line-height: 8rem;
          .textStart {
            padding: 5% 0 !important;
            white-space: pre-wrap !important;
          }

          > div {
            align-items: center;
            width: fit-content !important;

            font-family: fonts.$NANUM;
            text-align: center;
          }

          .bar {
            // display: block;
            display: none;
            width: 1%;
            padding: 0 2rem;
          }
        }

        .titleBox {
          justify-content: space-between;

          .title {
            width: 100%;
            padding: 3rem 0;
            font-family: fonts.$LIGHT;
            font-size: 7rem;
            line-height: 1.52;
          }

          .buttonWrapper {
            position: relative;
            display: flex !important;
            align-items: center;
            width: 20%;
            min-width: fit-content;

            > button {
              font-size: 5rem;
              line-height: 10rem;
            }
          }
        }

        .empty {
          font-size: 6rem;
          line-height: 16rem;
        }
      }
    }

    .partnershipInquiry {
      > h1 {
        font-size: 8rem;
        font-weight: bold;
        padding: 3% 0;
      }
      .row_table {
        font-size: 7rem;
        line-height: 23.5rem;
        letter-spacing: -0.261rem;

        input {
          width: 100%;
          font-size: 7rem;
        }

        .addInput {
          width: 42%;
          margin-right: 2%;
        }

        .addFileBtn {
          width: 25% !important;
          font-size: 5.5rem;
        }

        label {
          width: 25%;
        }

        .longInput {
          padding: 2rem 0;
          line-height: 10rem;

          textarea {
            height: 100rem !important;
            font-size: 7rem;
          }
        }

        .title_space {
          width: 42% !important;
          font-size: 7rem;
        }

        span {
          width: 75%;
          overflow: hidden;
          font-size: 7rem;
          color: colors.$GRAY8;
          text-overflow: ellipsis;

          > div > button {
            width: fit-content;
            padding: 0 11rem;
            font-size: 6.85rem;
            text-align: center;
          }
        }

        .caseTitle span {
          white-space: nowrap;
        }

        li {
          display: flex;
          align-items: center;

          textarea {
            width: 96%;
            height: 118.6rem;
          }

          > span {
            > input {
              line-height: 19rem;
              padding: 2% 3%;
            }

            > .buttonsWrapper {
              padding: 1rem 0;
              line-height: 17.95rem;
            }
          }

          .suggestion_content {
            white-space: break-spaces;
            line-break: anywhere;
          }
        }

        .rowTitle {
          white-space: nowrap;
        }

        .jamoonTitle {
          line-height: 12rem;
        }

        .jamoonSummary {
          padding: 5rem 1.75rem;
        }

        .summaryDownload {
          span {
            line-height: 15rem;

            > div {
              width: 40%;

              > button {
                width: 100% !important;
                font-size: 7rem !important;
                border-radius: 0 !important;
              }
            }
          }
        }

        .input_li {
          padding: 2.1rem 0 2.1rem 1.75rem;
          .selectors {
            width: 27%;
            font-size: 7rem;
            height: 22rem;
            padding: 0 5rem;
          }
          .selectorsApply {
            width: 36%;
          }
          > .label_middle {
            font-size: 7rem;
          }

          .span_short_width {
            span {
              overflow: unset;
              color: colors.$BLACK;
            }

            input {
              width: 85%;
            }

            .date {
              width: 75%;
            }

            .cd {
              height: 9rem;
            }
          }

          .accountInfoLabel {
            width: 29% !important;
          }

          > .account {
            display: flex;
            flex-direction: column;

            input,
            span {
              width: 100% !important;
              margin: 1.05rem;
            }

            :last-child {
              padding: 0;
              overflow: initial;
              font-family: fonts.$LIGHT;
              font-size: 7rem;
              line-height: 11rem;
              letter-spacing: -0.245rem;
              word-break: keep-all;
              white-space: pre-wrap;
            }
          }

          .label_middle {
            // width: 42%;
            width: 27%;
          }

          .suggestionContent {
            line-height: 10rem;
          }
        }

        .fileInput {
          span {
            width: 48% !important;
          }

          button {
            width: 26% !important;
            margin-left: 2rem;
            font-size: 7rem;
            line-height: 18rem;
          }
        }
      }

      .buttonWrapper {
        button {
          width: 25%;
          font-size: 7rem;
          line-height: 16rem;
        }
      }
    }
  }
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    height: auto;
  }
}

// @media screen and (min-width: 426px) and (max-width: 767px) {
@media screen and (min-width: 426px) and (max-width: 900px) {
  .companySection {
    margin-top: 13.7rem;
  }
}
