$RED: #ff9622;
$ORANGE: #ff9e1a;
$TEALGREEN: #00a3a3;
$BROWN: #4c4642;
$GREEN: #70ad47;
$REALRED: red;

$BLACK: #000000; // 푸터 이거씀, 메인
$BLACKE: #2f2a22;
$BLACK2: #202721; // 헤더 이거씀
$BLACK3: #202721; // 푸터 주소 이거씀
$GRAY3: #333333;
$GRAY6: #666666;
$GRAY8: #888888;
$GRAY7: #777777;
$GRAY7D: #7d7d7d;
$GRAY75: #757575;
$GRAY9: #999999;
$GRAY9B: #9b9b9b;
$GRAYA: #aaaaaa;
$GRAYC: #cccccc;
$GRAYD: #dddddd;
$GRAYL: #9b9893;
$GRAYS: #8e8a83;
$GRAYB: #bbbbbb;
$GRAY47: #474747;
$GRAYAB: #ababab;
$GRAYA4: #a4a4a4;
$GRAYE3: #e3e3e3;
$WHITE: #ffffff; // 회사소개 이거씀
$WHITE2: #ededed; // 헤더 이거씀

$KAKAO_BROWN: #635d2b;
$KAKAO_YELLOW: #fee500;
$NAVER_GREEN: #03c75a;

$TABLE_HEADER_GRAY: #fafafa;
$BORDER_GRAY: #dbdbdb;
$BOX_BG_GRAY: #fbfbfb;
$GRAY_BACKGROUND: #f4f4f4; // 푸터 배경

$INPUT_BACKGROUND: #f2f2f2;
$INPUT_BORDER: #d5d5d5;

$BG_OPACITY: rgba(0, 0, 0, 60%);
$MOBILE_BACKGROUND: #f2f0ed;
