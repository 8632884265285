@use '../../styles/constants/colors';
@use '../../styles/constants/fonts';

.headerWrapper {
  position: fixed;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  width: 100%;
  height: 25rem;
  // padding: 0 5.2%;
  background-color: colors.$GRAY_BACKGROUND;

  button {
    all: unset;
  }

  .menuButtonWrapper {
    width: 8.15%;
    position: absolute;
    top: 50%;
    left: 9rem;
    // background-color: colors.$BLACK;
    border: none;
    transform: translate(0, -50%);

    img {
      width: 100%;
    }
  }

  .logoWrapper {
    // width: 30%;
    width: 38%;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }

  .loginButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 18%;

    button {
      padding: 4rem 3rem;
      font-family: fonts.$LIGHT;
      font-size: 6rem;
      color: colors.$WHITE;
      background-color: colors.$GRAY9B;
    }
  }
}

@media screen and (min-width: 426px) and (max-width: 900px) {
  .headerWrapper {
    height: 14rem;

    .logoWrapper {
      width: 23%;
      img {
        width: 100%;
      }
    }
  }
}
