@use '../../styles/constants/colors';
@use '../../styles/constants/fonts';

.footer_container {
  background-color: colors.$GRAY_BACKGROUND;
}
.footer_cover {
  display: flex;
  justify-content: center;
  // max-width: 1000px;
  max-width: 1400px;
  height: auto;
  padding: 3% 5%;
  margin: 0 auto;
  .footer_name {
    margin-top: 3rem;
    // color: #b9b9b9;
    color: colors.$BLACK;
    line-height: 2.5rem;
    font-size: 2.5rem;
    font-family: fonts.$NANUMS_L;
    // font-family: NanumSquareEB;
  }
  > li > ul > li:first-child {
  }

  .footer_menu {
    display: flex;
    flex-direction: column;
    margin-left: 4rem;
    margin-right: 25rem;
    > li {
      font-size: 1.4rem;
      font-family: fonts.$NANUMS_L;
      color: colors.$BLACK3;
      > p {
        // color:colors.$BLACK
      }
    }
    .footer_menu_first {
      margin-top: 3rem;
      margin-bottom: 0.5rem;
      > span {
        // color:colors.$BLACK
      }
      > span > span {
        font-weight: bold;
        color: colors.$BLACK;
      }
    }
    .footer_menu_sec {
      margin-bottom: 3.5rem;
      > p {
        // color:colors.$BLACK
      }
      > p > span {
        font-weight: bold;
      }
    }
  }

  .social_menu {
    display: flex;
    margin-top: 3rem;
    > li {
      margin-right: 1rem;
    }
  }

  .footer_menu_first {
  }
  .qr_img {
    img {
      width: 8.5rem;
    }
  }
}

// .cf::after {
//   display: block;
//   clear: both;
//   content: '';
// }

// footer {
//   position: relative;
//   z-index: 0;
//   width: 100%;
//   height: auto;
//   padding: 7rem 0;
//   margin-top: 0;
//   background-color: colors.$GRAY_BACKGROUND;
// }

// footer .footer_cover {
//   max-width: 1400px;
//   height: auto;
//   padding: 3% 5%;
//   margin: 0 auto;

//   >:first-child {
//     float: left;
//     width: 50%;
//     padding-right: 2%;
//   }

//   >:last-child {
//     float: right;
//     width: 50%;
//     padding-left: 2%;
//   }
// }

// footer .footer_cover li:nth-child(2) {
//   position: relative;
// }

// footer .footer_cover > li > div {
//   font-size: 1.5rem;
//   line-height: 1.5;
//   color: colors.$GRAY6;
//   letter-spacing: -0.35px;
// }

// footer .footer_cover > li > div strong {
//   display: inline-block;
//   width: 1.5rem;
//   height: 1.4rem;
//   margin-top: 0.2rem;
//   font-size: 2rem;
//   line-height: 14px;
//   text-align: center;
//   vertical-align: middle;
// }

// footer .footer_cover > li div span {
//   display: block;
//   font-family: fonts.$NANUM;
//   font-size: 1.5rem;
//   letter-spacing: 0.2px;
// }

// footer .footer_cover > li div span:nth-child(1) {
//   font-size: 1.5rem;
//   letter-spacing: -0.55px;
// }

// footer .footer_cover > li div span:nth-child(3) {
//   margin-top: 1.2rem;
//   line-height: 1.5;
// }

// footer .footer_cover > li div .qrCode {
//   width: 8.5rem;
// }

// footer .footer_cover > li div img {
//   display: block;
//   float: left;
//   // width: 16rem;
//   width: 15rem;
//   margin-top: 2rem;
// }

// footer .footer_cover > li div a img {
//   // padding-top: 3.5rem;
//   padding-top: 4.5rem;
//   padding-left: 1rem;
// }

// footer .footer_cover li .t_menu {
//   top: 0;
//   right: 0;
//   float: right;
//   cursor: pointer;
// }

// footer .footer_cover li .t_menu li {
//   box-sizing: border-box;
//   float: left;
//   padding-left: 3rem;
//   font-size: 1.6rem;
//   font-weight: regular;
// }

// footer .footer_cover li .t_menu li a {
//   color: colors.$GRAY8;
// }

// footer .footer_cover li .t_menu li:nth-child(4),
// footer .footer_cover li .t_menu li:nth-child(5) {
//   text-align: right;
// }

// footer .footer_cover li .b_menu {
//   box-sizing: border-box;
//   float: right;
//   margin-top: 1rem;
//   font-size: 1.6rem;
//   color: #202721;
// }

// footer .footer_cover li .b_menu > li {
//   float: left;
//   line-height: 2.1;
// }

// footer .footer_cover li .b_menu > li:nth-child(1) {
//   float: right;
//   padding-left: 3rem;
// }

// footer .footer_cover li .b_menu > li > ul {
//   float: left;
// }

// footer .footer_cover li .b_menu > li > ul:nth-child(1) li {
//   width: 78px;
//   font-weight: bold;
// }

// footer .footer_cover li .b_menu > li > ul:nth-child(2) li {
//   color: colors.$GRAY8;
// }

// footer .footer_cover li .b_menu > li > ul:nth-child(2) li:nth-child(3) {
//   letter-spacing: -1px;
// }

// footer .footer_cover li .b_menu > li:nth-child(2) span {
//   font-size: 1.9rem;
//   font-weight: bold;
//   line-height: 30px;
//   color: #202721;
// }

// footer .footer_cover li .b_menu > li:nth-child(2) {
//   font-size: 1.3rem;
//   color: colors.$GRAY8;
// }

// footer .footer_cover li .b_menu > li:nth-child(3) {
//   margin-top: 4rem;
//   clear: both;
//   cursor: pointer;
// }

// footer > ul {
//   max-width: 1400px;
//   padding: 0 5% 3%;
//   margin: 0 auto;
//   font-size: 1.2rem;
//   line-height: 20px;
//   color: colors.$GRAY8;
//   text-align: justify;
//   letter-spacing: -0.4px;
// }

// footer > p span {
//   display: block;
//   margin-top: 2rem;
//   font-family: myriad;
//   font-size: 1.3rem;
//   letter-spacing: 0.2px;
// }

// .m_footer {
//   display: none;
//   margin: 0;
// }

// .workhour_info > li {
//   line-height: 22px;
// }

// @media screen and (max-width: 767px) {
//   footer {
//     display: none;
//   }

//   .m_footer {
//     display: block;
//     min-width: auto;
//     background-color: colors.$WHITE;
//   }

//   /* 공지사항 */
//   .m_footer table {
//     position: relative;
//     box-sizing: border-box;
//     width: 100%;
//     height: auto;
//     margin-top: 50px;
//     color: #202721;
//   }

//   .m_footer table th {
//     padding-bottom: 20px;
//     font-size: 2rem;
//     text-align: left;
//   }

//   .m_footer table tr {
//     width: 100%;
//     font-size: 1.4rem;
//     line-height: 20px;
//   }

//   .m_footer table tr td:nth-child(1) {
//     display: inline-block;
//     width: 76%;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//   }

//   .m_footer table tr td:nth-child(2) {
//     display: inline-block;
//     width: 20%;
//     margin-left: 4%;
//   }

//   .m_footer .button a {
//     position: absolute;
//     top: 4%;
//     right: 3%;
//     font-family: fonts.$LIGHT;
//     font-size: 4rem;
//     font-weight: lighter;
//     color: #acacac;
//   }

//   /* 푸터 */
//   .m_footer {
//     box-sizing: border-box;
//     width: 100%;
//     padding: 0 5%;
//     border: 0;
//   }

//   .m_footer > ul > li {
//     width: 100%;
//   }

//   .m_footer > ul > li:nth-child(1) {
//     padding: 15px 0;
//     font-family: myriad;
//     font-size: 1.2rem;
//     color: colors.$GRAY8;
//     border-top: 1px solid #acacac;
//     border-bottom: 1px solid #acacac;
//   }

//   .m_footer > ul > li:nth-child(1) img {
//     display: block;
//     float: right;
//     width: 3vw;
//     height: auto;
//     margin-top: 1%;
//     margin-right: 3%;
//   }

//   .m_footer > ul > li:nth-child(2) ul li {
//     float: left;
//     padding-right: 2%;
//     margin: 20px 0 19px 2%;
//     font-size: 1.2rem;
//     font-weight: bold;
//     color: colors.$BLACK;
//     border-right: 2px solid colors.$BLACK;
//   }

//   .m_footer > ul > li:nth-child(2) ul li:nth-child(1) {
//     margin-left: 0;
//   }

//   .m_footer > ul > li:nth-child(2) ul li:nth-child(5) {
//     padding: 0;
//     border: 0;
//   }

//   .m_footer > ul > li p {
//     padding-bottom: 22px;
//     font-size: 1.2rem;
//     line-height: 2rem;
//     color: colors.$GRAY8;
//   }

//   .m_footer > ul > li:nth-child(4) p {
//     letter-spacing: -1px;
//   }

//   .m_footer > ul > li:nth-child(4) div {
//     width: 30px;
//     height: 30px;
//     border-top: 2px solid #acacac;
//   }

//   .m_footer .home a {
//     display: block;
//     width: 111%;
//     height: 40px;
//     margin-left: -6%;
//     font-size: 1.8rem;
//     line-height: 40px;
//     color: colors.$WHITE;
//     text-align: center;
//     background-color: colors.$BLACK;
//   }
// }

// @media screen and (min-width: 615px) and (max-width: 767px) {
//   .m_footer table tr {
//     line-height: 30px;
//   }
// }

// @media screen and (min-width: 539px) and (max-width: 614px) {
//   .m_footer table tr {
//     line-height: 25px;
//   }
// }

// @media screen and (max-width: 480px) {
//   .m_footer table tr {
//     font-size: 1.6rem;
//     line-height: 23px;
//   }
// }

// @media screen and (max-width: 415px) {
//   .m_footer > ul > li:nth-child(2) ul li:nth-child(5) {
//     padding: 0;
//   }
// }

// @media screen and (max-width: 330px) {
//   .m_footer > ul > li:nth-child(2) ul li {
//     font-size: 1.3rem;
//   }
// }
