@use '../../styles/constants/colors';
@use '../../styles/constants/fonts';

.bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  width: 100%;
  height: 100%;
  background-color: colors.$BG_OPACITY;
}

.sideMenu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 88%;
  height: 100%;
  // height: 190vw;
  overflow-y: scroll;
  background-color: colors.$WHITE;
  transition: all 0.25s;

  button {
    font-family: fonts.$LIGHT !important;
  }

  .menuWrapper {
    padding: 5% 0;

    .buttonsWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 5%;

      :first-child {
        flex: 0.8;

        img {
          width: 70rem;
          margin: 0 auto;
        }
      }

      :last-child {
        flex: 0.2;

        img {
          width: 40px;
          margin: 0 auto;
        }
      }
    }

    .topMenuWrapper {
      padding: 20rem 10% 10rem;
      border-bottom: 1px solid colors.$BORDER_GRAY;

      ul {
        display: flex;
        justify-content: space-between;

        li {
          font-size: 7rem;
        }

        .bar {
          margin: 0 2%;
          color: colors.$BORDER_GRAY;
        }
      }
    }

    .itemWrapper {
      ul {
        display: flex;
        flex-direction: column;
        padding: 5% 10%;

        > li {
          padding: 6% 0;
          font-size: 9rem;

          > a {
            font-family: fonts.$LIGHT;
          }
        }
      }

      .menuContainer {
        // font-size: 5rem;
        // background-color: #2e2e2e;
        > li:last-child {
          padding-bottom: 20%;
        }
        .upperList {
          // margin-top: 5rem;

          .mystyle {
          }

          > button {
            display: flex;
            align-items: center;
            // color: colors.$WHITE;
            cursor: pointer;
            // background-color: #2e2e2e;
            border: none;

            > span {
              font-size: 1.9rem;
            }

            > span:first-child {
              width: 7rem;
              margin-right: 5rem;
              color: colors.$BLACK;
              font-size: 10rem;
              white-space: nowrap;
            }

            // >span:not(:last-child) {display: none;}
          }

          .show {
            // display: none;
          }

          .dropMenu {
            display: none;

            .linkBtn,
            .linkbtnafter {
              // margin-top: 2rem;
              // margin-left: 1rem;
              // font-size: 1.7rem;

              > button {
                color: colors.$GRAY8;
                // background-color: #2e2e2e;
                border: none;
                > a {
                  font-size: 8rem;
                  text-decoration: none;
                  color: colors.$BLACK;
                }
                a:visited {
                  color: colors.$BLACK2;
                }
              }
            }

            .linkBtnAfter {
              margin-left: 1rem;
              font-size: 1.7rem;
              color: #00ffa2;
            }
          }

          .dropMenuAfter {
            display: block;

            .linkBtn,
            .linkbtnafter {
              margin-top: 2rem;
              margin-left: 1rem;
              font-size: 1.7rem;

              > button {
                color: colors.$GRAY8;
                background-color: #2e2e2e;
                border: none;
              }
            }

            .linkBtnAfter {
              margin-left: 1rem;
              font-size: 1.7rem;
              // color: colors.$RED;
              color: #00ffa2;
            }
          }
        }

        .arrow,
        .arrowAfter {
          display: flex;
          align-items: center;
          justify-content: center;
          // width: 35px;
          height: 25px;
          cursor: pointer;

          &::after {
            width: 0;
            height: 0;
            content: '';
            border-top: 4px solid #bbbbbb;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
            transform: rotate(180deg);
            // transform: ${props => (props.toggle ? "rotate(180deg)" : "rotate(0deg)")};
          }
        }

        .arrowAfter {
          &::after {
            transform: rotate(360deg);
          }
        }

        .show {
          display: none;
        }
      }
    }

    .subItemWrapper {
      border-top: 1px solid colors.$BORDER_GRAY;

      ul {
        display: flex;
        flex-direction: column;
        padding: 5% 10%;

        li {
          padding: 5% 0;
          font-size: 7rem;

          button {
            display: flex;
            align-items: center;

            strong {
              padding-right: 3rem;
            }

            .countBubble {
              padding: 0.5rem 5rem;
              margin: 0 3rem;
              font-size: 6rem;
              color: colors.$WHITE;
              background-color: colors.$RED;
              border-radius: 15px;
            }
          }
        }
      }
    }
  }

  .sideFooterWrapper {
    position: absolute;
    bottom: 0;
    display: contents;

    .bottomMenu {
      ul {
        display: flex;
        justify-content: space-around;
        padding: 8.7rem 0;
        font-size: 6rem;
        background-color: colors.$GRAY_BACKGROUND;
      }
    }

    .copy {
      padding: 9rem 0;
      font-family: fonts.$NANUM;
      font-size: 4.5rem;
      font-weight: bold;
      color: colors.$WHITE;
      text-align: center;
      letter-spacing: 0.25rem;
      background-color: colors.$ORANGE;
    }

    .homepage {
      padding: 7rem 0;
      font-family: fonts.$NANUM;
      font-size: 8rem;
      font-weight: bold;
      color: colors.$WHITE;
      text-align: center;
      letter-spacing: -0.315rem;
      background-color: colors.$BLACK;
    }
  }
}

.showMenu {
  left: 0%;
}

// .hideMenu {
//   left: -100%;
// }

@media screen and (min-width: 426px) and (max-width: 900px) {
  .sideMenu {
    img {
      width: 30rem !important;
    }
  }

  .itemWrapper {
    > ul {
      > li {
        // padding: 1% 0 !important;
      }
    }
  }

  .menuContainer {
    button {
      span:first-child {
        font-size: 7rem !important;
      }
    }
  }

  .linkBtn,
  .linkbtnafter button {
    a {
      font-size: 5rem !important;
    }
  }
}
