@use '../../styles/constants/colors';
@use '../../styles/constants/fonts';

// 제목 css
%title {
  height: 5rem;
  min-height: 5rem;
  // margin-bottom: 5rem;
  overflow: hidden;
  font-family: fonts.$LIGHT;
  font-size: 2.2rem;
  // font-weight: 300;
  font-weight: 300;
  // line-height: 3.5rem;
  line-height: normal;
}

.searchContainer {
  width: 68rem;
  margin: 0 auto;

  .topBox {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    li:not(:first-child) {
      margin-left: 1rem;
    }

    > p {
      margin-right: 1rem;
      font-size: 1.7rem;
    }

    > ul {
      display: flex;
      font-size: 1.5rem;
      color: #888888;
    }
  }

  .searchBox {
    display: flex;

    input {
      width: 85%;
      padding: 1.5rem 2rem;
      font-size: 1.7rem;
    }

    button {
      width: 15%;
      padding: 1.5rem 2rem;
      margin-left: 0.5rem;
      text-align: center;
    }
  }
}

.container {
  max-width: 1280px;
  // margin: calc((96px / 1280px) * 100%) auto;
  // margin: calc((60px / 1280px) * 100%) auto;
  margin: 9.5rem auto;

  .leftBox {
    display: flex;
    margin-bottom: 6rem;

    .box {
      // width: 70%;
      // margin-right: 5rem;
      width: 100%;
    }
  }

  .box {
    // height: 107rem; // 박스 고정값
    font-size: 1.7rem;

    .contents {
      // display: flex;
      flex-wrap: wrap;
      // justify-content: center;
      width: 100%;
      // max-width: 1280px;
      // margin: 0 auto;
      // margin-top: 8.59375%;

      .noResult {
        margin-top: 10rem;
        text-align: center;
      }

      li:not(:nth-child(1)) {
        // margin-right: calc((80px / 1280px) * 100%);
        // margin-bottom: 7rem;
        margin-top: 3rem;
      }

      // li:last-child {
      li:nth-child(3n) {
        // margin-right: 0;
        // margin-bottom: 2.5rem;
      }

      li:nth-child(3n + 1) {
        margin-left: 0;
      }

      // 오른쪽줄
      // li:not(:nth-child(3n))::after {
      //   display: block;
      //   align-items: center;
      //   width: 0.25%;
      //   height: 98%;
      //   content: '';
      //   background-color: #9b9893;
      //   opacity: 0.5;
      // }

      li:nth-child(n + 4):nth-child(-n + 6)::after {
        display: block;
        align-items: center;
        width: 0.25%;
        height: 92%;
        margin-top: 1.5rem;
        content: '';
        background-color: #9b9893;
        opacity: 0.5;
      }

      // li:nth-child(n+2):nth-child(-n+3)::before {
      li:nth-child(n + 1):nth-child(-n + 3)::before {
        position: absolute;
        bottom: 0;
        width: 95%;
        height: 0.15%;
        content: '';
        background: #9b9893;
        opacity: 0.5;
      }

      li:nth-child(n + 4) {
        .content,
        .plusBox {
          margin-top: 2.2rem;
        }
      }
      li:last-child:last-child::after {
        display: none;
      }

      li {
        position: relative;
        display: flex;

        // width: calc(33.333% - calc((14px / 1280px) * 100%));
        // min-height: 17rem;
        // height: 21rem;
        min-height: 21rem;
        padding: 0;
        margin-right: 0;
        // margin-bottom: 3.5rem;
        // margin-left: calc((20px / 1280px) * 100%);
        // overflow: hidden;
        cursor: pointer;

        .plusBox {
          display: flex;
          // align-items: center;
          justify-content: center;
          width: 1.5rem;
          height: 1.5rem;
          margin: 0 1rem 0 0.5rem;
          color: colors.$WHITE;
          // background: colors.$GRAY8;
          background: #e6e5e3;
        }

        .content {
          width: 100%;

          .img {
            width: 100%;
            height: 240px;
            margin-bottom: 3.3rem;
            border: solid 1px #d5d5d5;
            border-radius: 7px;

            .imgs {
              width: 100%;
              height: 100%;
              border: 0;
              object-fit: cover;
              object-position: left top;
            }
          }

          .title {
            @extend %title;
          }

          .date {
            margin: 2rem 0;
            font-family: fonts.$LIGHT;
            font-size: 1.7rem;
            font-weight: 300;
          }

          .contents {
            justify-content: flex-start;
            // height: 12rem;
            margin: 2rem 0;
            margin-bottom: 3.5rem;
            overflow: hidden;
            font-size: 1.7rem;
            line-height: 1.43;
            color: #8e8a83;
            // justify-content: flex-start;
            // height: 12rem;
            // margin: 2rem 0;
            // margin-bottom: 3.5rem;
            // overflow: hidden;
            // font-size: 1.7rem;
            // line-height: 1.43;
            // color: #8e8a83;
          }

          .exp {
            // max-height: 8vh;
            // height: 10rem;
            height: 12rem;
            overflow: hidden;
            font-size: 1.7rem;
            line-height: 2.5rem;
            // line-height: 1.53;
            color: colors.$GRAY8;
            letter-spacing: -0.6px;
          }
        }

        .LawContents {
          height: 15rem;
          margin-bottom: 3rem;
          overflow: hidden;
          line-height: 1.3;

          :first-child {
            margin-bottom: 2rem;
          }

          :nth-child(2) {
            color: #8e8a83;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  // 날짜
  %date {
    // margin: calc((150px / 1920px) * 100%) 0;
    margin: 5rem 0;
    font-size: 6rem;
    color: colors.$GRAY8;
  }

  %title {
    // height: 15rem;
    height: 13.2rem;
    // margin-bottom: 5rem;
    overflow: hidden;
    font-family: fonts.$LIGHT;
    font-size: 11.2rem;
    // font-weight: 300;
    font-weight: 300;
    // line-height: 3.5rem;
    line-height: normal;
  }

  body .searchContainer {
    // width: 80rem;
    width: 100%;
    margin: 0 auto;

    .searchBox {
      display: flex;

      input {
        // width: 100%;
        width: 70%;
        padding: 5.5rem 2rem;
        margin-right: 3rem;
        font-size: 8rem;
      }

      button {
        width: 30%;
        padding: 1.5rem 2rem;
        margin-left: 0.5rem;
        text-align: center;
      }
    }
  }

  .container {
    max-width: 1280px;
    margin: 9.5rem auto;

    .box {
      // height: 107rem; // 박스 고정값
      font-size: 1.7rem;

      .contents {
        flex-wrap: wrap;
        width: 100%;

        .noResult {
          margin-top: 10rem;
          font-size: 8rem;
          text-align: center;
        }

        li:last-child:last-child::after {
          display: none;
        }

        li {
          .content {
            width: 100%;
            padding: 4rem 0;

            .contents {
              justify-content: flex-start;
              height: 23rem;
              margin: 5rem 0;
              // margin-bottom: 3.5rem;
              overflow: hidden;
              font-size: 7.7rem;
              line-height: 1.43;
              color: #8e8a83;
            }
          }

          .LawContents {
            height: 42rem;
            // min-height: 42rem;
            margin-bottom: 3rem;
            overflow: hidden;
            font-size: 6rem;
            line-height: 1.3;

            :first-child {
              margin-bottom: 2rem;
            }

            :nth-child(2) {
              // min-height: 3rem;
              max-height: 30rem;
              overflow: hidden;
              color: #8e8a83;
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 425px) and (max-width: 767px) {
  body .container {
    padding: calc((75px / 1280px) * 100%) auto;
    margin: 0;
    background-color: #f2f0ed;

    .box {
      .contents {
        padding: calc((150px / 1920px) * 100%)
          calc((110px / 1920px) * 100%);
        padding-top: 0;

        li:not(:nth-child(3n)) {
          margin-right: 0;

          > div {
            width: 100%;
          }
        }

        li:nth-child(odd) {
          margin-right: calc((100px / 1920px) * 100%);
        }

        li:nth-last-child(-n + 2) {
          margin-bottom: 0;
        }

        li {
          width: 45%;
          // max-height: 38vh;
          max-height: 100%;

          .content {
            .img {
              // height: 100%;
              height: 30vw;
              margin-bottom: calc((150px / 1920px) * 100%);
              background-color: colors.$BLACK;
              border-radius: 0;
            }

            .title {
              font-size: 6.5rem;
              line-height: 1.5;
            }

            .date {
              margin: calc((150px / 1920px) * 100%) 0;
              font-size: 5rem;
            }

            .exp {
              // max-height: 21vw;
              height: 26rem;
              font-size: 6rem;
              line-height: 6.5rem;
            }
          }
        }
      }
    }
  }
}
