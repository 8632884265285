$GMARKET: 'Gmarket';
$GMARKET_BOLD: 'GmarketBold';
$GMARKET_LIGHT: 'GmarketLight';
$GMARKET_TTF: 'GmarketSansTTF';
$NANUM: 'NanumBarunGothic';
$LIGHT: 'NanumBarunGothicLight';
$NANUMS_R: 'NanumSquareR'; // 헤더 이거씀
$NANUMS_EB: 'NanumSquareEB';
$NANUMS_B: 'NanumSquareB';

$NANUMS_L: 'NanumSquareL'; // 푸터 이거씀 , 메인
