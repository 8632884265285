@use '../../styles/constants/colors';
@use '../../styles/constants/fonts';

.partnershipInquiry {
  h1 {
    margin-bottom: 2.1rem;
    font-family: fonts.$NANUMS_L;
    font-size: 2.5rem;
    text-align: left;
    letter-spacing: -0.88px;
  }

  .row_table {
    font-size: 1.8rem;

    > :first-child {
      // background-color: colors.$TABLE_HEADER_GRAY;
      border-top: 0.3rem solid colors.$BLACK;
    }

    > :nth-child(4) {
      padding: 1.1rem 1.75rem;
    }

    > :nth-child(4),
    :nth-child(8) {
      display: flex;
      align-items: center;
    }

    .addInput {
      width: 73%;
      margin-right: 1%;
    }

    .summaryDownload {
      > span {
        > div {
          > button {
            all: unset !important;
            width: 12.5% !important;
            padding: 0.7rem 0 !important;
            font-size: 1.8rem !important;
            color: colors.$WHITE !important;
            text-align: center !important;
            letter-spacing: -0.63px !important;
            cursor: pointer !important;
            background-color: colors.$BLACK !important;
          }
        }
      }
    }

    li {
      height: auto;
      padding: 1.75rem;
      border-bottom: 1px solid #dbdbdb;

      > span {
        > input {
          width: 96%;
          height: inherit;
        }
      }

      textarea {
        width: 96%;
        height: 24.5rem;
        line-height: 1.5;
      }
    }

    .input_li {
      padding: 1.2rem 0 1.2rem 1.75rem;
      display: flex;
      align-items: center;
      font-family: fonts.$NANUMS_L;

      > .label_middle {
        vertical-align: middle;
      }

      > .span_short_width {
        display: inline;

        > span {
          width: fit-content;
          padding: 0 1%;
        }

        > input {
          width: 30.92%;
        }

        .date {
          width: 16.85%;
        }

        .cd {
          height: 2rem;
        }
      }

      > .account {
        display: inline-block;
        width: 90%;

        > :first-child {
          width: 10%;
          margin-right: 1%;
        }

        > :nth-child(2) {
          width: 19.8%;
        }

        :last-child {
          width: 50%;
          padding: 0 1%;
          font-size: 1.5rem;
          color: colors.$GRAY6;
          letter-spacing: -0.52px;
        }
      }

      .selectors {
        width: 11%;
        height: 5rem;
        padding: 0 2rem;
        margin-right: 1rem;
        font-size: 1.7rem;
        background: url('../../assets/images/arrow.png')
          no-repeat 95% 50%;
        // color: colors.$GRAYA;
        background-color: #f4f4f4;
        border: none;
        appearance: none;
        font-family: fonts.$NANUMS_L;
      }

      .selectorsApply {
        width: 18%;
      }
    }

    label {
      display: inline-block;
      width: 10%;
      letter-spacing: -0.63px;
      vertical-align: top;
    }

    span {
      display: inline-block;
      width: 90%;

      > div > :first-child {
        margin-right: 0.6rem;
      }

      > div > button {
        all: unset;
        width: 12.5%;
        padding: 0.7rem 0;
        font-size: 1.8rem;
        color: colors.$WHITE;
        text-align: center;
        letter-spacing: -0.63px;
        cursor: pointer;
        background-color: #413931;
      }
    }

    .bank {
      width: 10%;
    }

    .orange_text {
      color: colors.$ORANGE;
    }

    .bold {
      font-weight: bold;
    }
  }

  .text_counter {
    padding: 1.7rem 0;
  }

  .title_space {
    margin-top: 1rem;
  }

  .suggestion_content {
    line-height: 1.72;
    letter-spacing: -0.63px;
  }

  .middleInput {
    width: 78% !important;

    input {
      width: 95%;
    }
  }

  .addFileBtn {
    all: unset;
    width: 12% !important;
    // padding: 0.85rem 0;
    padding: 1.2rem 0;
    font-size: 1.8rem;
    color: colors.$WHITE;
    text-align: center;
    cursor: pointer;
    background-color: #413931;
  }

  .buttonWrapper {
    display: flex;
    justify-content: end;
    // padding: 6rem 0;
    margin-top: 8rem;
    cursor: pointer;
  }

  .listBtn {
    all: unset;
    width: 12%;
    padding: 1.25rem 0;
    font-size: 1.8rem;
    color: colors.$WHITE;
    text-align: center;
    // background-color: #413931;
    background-color: colors.$RED;
  }
}

@media screen and (max-width: 425px) {
  .partnershipInquiry {
    > h1 {
      font-size: 8rem;
      font-weight: bold;
      padding: 3% 0;
    }
    .row_table {
      font-size: 7rem;
      line-height: 23.5rem;
      letter-spacing: -0.261rem;

      input {
        width: 100%;
        font-size: 7rem;
      }

      .addInput {
        width: 42%;
        margin-right: 2%;
      }

      .addFileBtn {
        width: 25% !important;
        font-size: 5.5rem;
      }

      label {
        width: 25%;
      }

      .longInput {
        padding: 2rem 0;
        line-height: 10rem;

        textarea {
          height: 100rem !important;
          font-size: 7rem;
        }
      }

      .title_space {
        width: 42% !important;
        font-size: 7rem;
      }

      span {
        width: 75%;
        overflow: hidden;
        font-size: 7rem;
        color: colors.$GRAY8;
        text-overflow: ellipsis;

        > div > button {
          width: fit-content;
          padding: 0 11rem;
          font-size: 6.85rem;
          text-align: center;
        }
      }

      .caseTitle span {
        white-space: nowrap;
      }

      li {
        display: flex;
        align-items: center;

        textarea {
          width: 96%;
          height: 118.6rem;
        }

        > span {
          > input {
            line-height: 19rem;
            padding: 2% 3%;
          }

          > .buttonsWrapper {
            padding: 1rem 0;
            line-height: 17.95rem;
          }
        }

        .suggestion_content {
          white-space: break-spaces;
          line-break: anywhere;
        }
      }

      .rowTitle {
        white-space: nowrap;
      }

      .jamoonTitle {
        line-height: 12rem;
      }

      .jamoonSummary {
        padding: 5rem 1.75rem;
      }

      .summaryDownload {
        span {
          line-height: 15rem;

          > div {
            width: 40%;

            > button {
              width: 100% !important;
              font-size: 7rem !important;
              border-radius: 0 !important;
            }
          }
        }
      }

      .input_li {
        padding: 2.1rem 0 2.1rem 1.75rem;
        .selectors {
          width: 27%;
          font-size: 7rem;
          height: 22rem;
          padding: 0 5rem;
        }
        .selectorsApply {
          width: 36%;
        }
        > .label_middle {
          font-size: 7rem;
        }

        .span_short_width {
          span {
            overflow: unset;
            color: colors.$BLACK;
          }

          input {
            width: 85%;
          }

          .date {
            width: 75%;
          }

          .cd {
            height: 9rem;
          }
        }

        .accountInfoLabel {
          width: 29% !important;
        }

        > .account {
          display: flex;
          flex-direction: column;

          input,
          span {
            width: 100% !important;
            margin: 1.05rem;
          }

          :last-child {
            padding: 0;
            overflow: initial;
            font-family: fonts.$LIGHT;
            font-size: 7rem;
            line-height: 11rem;
            letter-spacing: -0.245rem;
            word-break: keep-all;
            white-space: pre-wrap;
          }
        }

        .label_middle {
          // width: 42%;
          width: 27%;
        }

        .suggestionContent {
          line-height: 10rem;
        }
      }

      .fileInput {
        span {
          width: 48% !important;
        }

        button {
          width: 26% !important;
          margin-left: 2rem;
          font-size: 7rem;
          line-height: 18rem;
        }
      }
    }

    .buttonWrapper {
      button {
        width: 25%;
        font-size: 7rem;
        line-height: 16rem;
      }
    }
  }
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    height: auto;
  }
}

// @media screen and (min-width: 426px) and (max-width: 767px) {
@media screen and (min-width: 426px) and (max-width: 900px) {
  .companySection {
    margin-top: 13.7rem;
  }
}
