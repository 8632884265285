@use '../../styles/constants/colors';
@use '../../styles/constants/fonts';

.noticeListWrapper {
  margin-bottom: 5%;
  > li {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    h1 {
      margin-bottom: 2.1rem;
      font-family: fonts.$LIGHT;
      font-size: 3rem;
      text-align: left;
      letter-spacing: -0.1rem;
    }
  }

  .flexColumn {
    display: flex;
    flex-direction: column;
  }

  .flexRow {
    display: flex;
    flex-direction: row;
  }

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .underLineNone {
    text-decoration: none !important;
    cursor: default !important;
  }

  .tableWrapper {
    .tableHeaderWrapper {
      background-color: colors.$TABLE_HEADER_GRAY;
      border-top: 3px solid colors.$BLACK;
      border-bottom: 1px solid colors.$BORDER_GRAY;

      > div > div {
        font-family: fonts.$NANUMS_L;
      }
    }

    .rowWrapper {
      border-bottom: 1px solid colors.$BORDER_GRAY;

      .titleBox {
        font-family: fonts.$LIGHT;
        font-size: 1.8rem;
        line-height: 1.52;
      }
    }

    .infoBox {
      align-items: center;
      width: 100%;
      font-size: 1.8rem;
      cursor: pointer;

      > div {
        padding: 2rem 1rem;
        font-family: fonts.$NANUMS_L;
        text-align: center;
      }

      .serviceType {
        width: 5%;
      }

      .caseType {
        width: 15%;
      }

      .others {
        width: 15%;
        > button {
          width: 70%;
          height: 3.5rem;
          cursor: pointer;
          border-radius: 2px;
          font-family: fonts.$NANUMS_L;
          font-size: 1.8rem;
        }
        .working {
          background-color: colors.$RED;
          color: colors.$WHITE;
        }
        .ending {
          background-color: colors.$GRAYA;
          color: colors.$WHITE;
        }
      }
      .date {
        // width: 28%;
        width: 22%;
      }

      .no {
        width: 10%;
      }

      .title {
        // width: 75%;
        width: 35%;

        &:hover {
          text-decoration: underline;
          cursor: pointer !important;
        }
      }

      .suggestionCount {
        width: 12%;
        padding: 0;
      }

      .bar {
        display: none;
      }

      .textStart {
        text-align: start;
      }

      .buttonWrapper {
        display: flex;
        align-items: center;
        width: 17%;
        min-width: fit-content;

        > button {
          line-height: 8rem;
        }
      }
    }

    .empty {
      display: flex;
      justify-content: center;
      font-size: 2rem;
      line-height: 6rem;
      border-bottom: 1px solid #dbdbdb;
    }
  }
}

@media screen and (max-width: 425px) {
  .noticeListWrapper {
    > li {
      padding: 3rem 0;
      border-bottom: 1.1rem solid black;

      h1 {
        font-size: 9.85rem;
        line-height: 12.5rem;
        letter-spacing: -0.345rem;
      }
    }

    .flexColumn {
      display: flex;
      flex-direction: column;
    }

    .flexRow {
      display: flex;
      flex-direction: row;
    }

    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .tableWrapper {
      .tableHeaderWrapper {
        display: none;
      }
      .rowWrapper {
        padding: 6rem 0;
        border-bottom: 1px solid colors.$BORDER_GRAY;
      }

      .infoBox {
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 6rem 0 3rem 3rem;
        font-size: 6rem;
        flex-direction: column;
        font-size: 7rem !important;
        align-items: start !important;
        line-height: 8rem;
        .textStart {
          padding: 5% 0 !important;
          white-space: pre-wrap !important;
        }

        > div {
          align-items: center;
          width: fit-content !important;

          font-family: fonts.$NANUM;
          text-align: center;
        }

        .bar {
          // display: block;
          display: none;
          width: 1%;
          padding: 0 2rem;
        }

        .others {
        
          > button {
            width: 34rem;
            height: 12rem;
            font-size: 6rem;
          }
          .working {
          }
     
        }


      }

      .titleBox {
        justify-content: space-between;

        .title {
          width: 100%;
          padding: 3rem 0;
          font-family: fonts.$LIGHT;
          font-size: 7rem;
          line-height: 1.52;
        }

        .buttonWrapper {
          position: relative;
          display: flex !important;
          align-items: center;
          width: 20%;
          min-width: fit-content;

          > button {
            font-size: 5rem;
            line-height: 10rem;
          }
        }
      }

      .empty {
        font-size: 6rem;
        line-height: 16rem;
      }
    }
  }
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    height: auto;
  }
}

// @media screen and (min-width: 426px) and (max-width: 767px) {
@media screen and (min-width: 426px) and (max-width: 900px) {
  .companySection {
    margin-top: 13.7rem;
  }
}
