@use '../../../styles/constants/colors';
@use '../../../styles/constants/fonts';

.main > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > p {
    font-family: fonts.$NANUMS_L;
    color: colors.$BLACK;
  }
  .mainTitle {
    font-size: 5rem;
    margin-top: 15rem;
    margin-bottom: 3.3rem;
    > span {
      color: colors.$RED;
    }
  }

  .subTitle {
    font-size: 1.5rem;
    max-width: 67rem;
    line-height: 2rem;
    text-align: center;
  }
}
@media screen and (max-width: 425px) {
  .mobileOff {
    display: none;
  }

  .main > div {
    .mainImg {
      width: 100%;
    }

    .mainTitle {
      font-size: 10.5rem;
      margin-top: 15rem;
      margin-bottom: 18.3rem;
    }
    .subTitle {
      display: none;
      font-size: 10.5rem;
      max-width: 90%;
      line-height: 10.5rem;
      text-align: center;
    }
  }
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    height: auto;
  }
}
