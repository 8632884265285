@use '../../styles/constants/colors';
@use '../../styles/constants/fonts';

%headerColor {
  background-color: #ededed;
}

%headerText {
  color: #ededed;
}

%headerTextB {
  color: #202721;
}

%menuSize {
  // width: 17rem;
  width: 16rem;
}

%columnLeft {
  // margin-left: 11vw;
  // margin-left: 16vw;
  // margin-left: 30.1rem;
  // margin-left: 27.5rem;
  // margin-left: 25rem;
  // margin-left: 29rem;
}

.hedaerContainer {
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 1200px;
  justify-content: center;
  // justify-content: space-between;
}
.hedaerLeft {
  text-align: center;
  // width: 20rem;
  // @extend %menuSize;
  font-size: 2.8rem;
  font-weight: bold;
  // @extend %headerText;
  // color: colors.$WHITE2;
  color: colors.$BLACK2;
  width: 28rem;
  // margin-right: 9rem;

  a {
    text-decoration: none;
    > p {
      text-align: left;
    }
  }
  a:hover,
  a:active {
    text-decoration: none;
  }
  a:visited {
    color: colors.$BLACK2;
  }
  a:hover {
    text-decoration: none;
  }
  a:focus {
    text-decoration: none;
  }
}
.hedaerRight {
  display: flex;
  // color: #ffff;
  // width: 50%;
  // width: 34%;
  width: 28rem;
  // justify-content: center;
  justify-content: right;

  > a {
    // @extend %headerText;
    > button {
      color: colors.$WHITE;
      // color: colors.$BLACK2;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: colors.$RED;
      cursor: pointer;
      width: 24rem;
      padding: 1rem 0;
      border-radius: 0.3rem;
    }
  }

  .secBtn {
    background-color: #000;
  }
}
.navbar {
  top: 0;

  z-index: 9999;
  font-family: Arial, Helvetica, sans-serif;
  // background: #fafafa;
  // border-top: 0.4rem solid colors.$BLACK;
  // border-bottom: 1px solid #dbdbdb;
}

.fix {
  position: fixed !important;
}

// .navbar a {
//   float: left;
//   padding: 1rem;
//   font-size: 1.6rem;
//   color: colors.$WHITE;
//   text-align: center;
//   text-decoration: none;
// }

.dropdown {
  height: 6.6rem;
  margin: 0 auto;
  font-family: fonts.$NANUMS_R;
  font-weight: normal;
  text-align: center;
}

.dropdown .dropbtn {
  // width: 20rem;
  @extend %menuSize;
  height: inherit;
  text-align: left;
  margin: 0;
  font-family: fonts.$NANUMS_R;
  font-size: 2rem;
  font-weight: normal;
  // @extend %headerTextB;
  color: colors.$BLACK2;
  letter-spacing: -0.88px;
  background-color: inherit;
  border: none;
  outline: none;
  padding: 0;
}

.dropdown .sel {
  color: colors.$RED;
}

.dropdown-content {
  position: absolute;
  left: 0;
  z-index: 1;
  display: none;
  width: 100%;
  background-color: colors.$WHITE2;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 20%);
  > section {
    display: flex;
    max-width: 1200px;
    justify-content: center;
    margin: 0 auto;
    .dropRight {
      width: 28rem;
      opacity: 0;
    }
    .dropLeft {
      // width: 35%;
      width: 28rem;
      opacity: 0;
    }
  }
}

.lawyer_dropdown-content {
  height: inherit !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.hedaerContainer:hover .hedaerLeft {
  color: colors.$BLACK2;
  background-color: colors.$WHITE2;
  a:visited {
    color: colors.$BLACK2;
  }
}

/* Create three equal columns that floats next to each other */
.column:first-child {
  // margin-left: 20rem;
  @extend %columnLeft;
}
.column {
  // width: 20rem;
  @extend %menuSize;
  padding: 1rem 0;
}

.column a,
.column button {
  display: block;
  float: none;
  padding: 1.6rem 0;
  margin: 0 auto;
  font-size: 1.6rem;
  color: colors.$BLACK2;
  // text-align: center;
  text-align: left;
  text-decoration: none;
}

.column a:hover,
.column button:hover {
  color: colors.$RED;
}

.row {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
}

/* Clear floats after the columns */
// .row::after {
//   display: table;
//   clear: both;
//   content: '';
// }

@media screen and (min-width: 426px) and (max-width: 1199px) {
  // @media screen and (max-width: 1279px) {
  html {
    .column:first-child {
      // margin-left: 47.5rem;
      // margin-left: 51.5rem;
      // margin-left: 26%;
    }
  }
}
// @media screen and (min-width: 1199px) and (max-width: 1279px) {
//   html {
//     .column:first-child{
//       margin-left: 28.5rem;
//     }
//   }
// }
