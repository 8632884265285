@font-face {
  font-family: NanumBarunGothic;
  src: url('../assets/fonts/NanumBarunGothic.ttf')
    format('truetype');
}

@font-face {
  font-family: NanumBarunGothicLight;
  src: url('../assets/fonts/NanumBarunGothicLight.ttf')
    format('truetype');
}

@font-face {
  font-family: Gmarket;
  src: url('../assets/fonts/GmarketSansTTFMedium.ttf')
    format('truetype');
}

@font-face {
  font-family: GmarketLight;
  src: url('../assets/fonts/GmarketSansTTFLight.ttf')
    format('truetype');
}

@font-face {
  font-family: GmarketBold;
  src: url('../assets/fonts/GmarketSansTTFBold.ttf')
    format('truetype');
}

@font-face {
  font-family: NanumSquareEB;
  src: url('../assets/fonts/NanumSquareEB.ttf')
    format('truetype');
}

@font-face {
  font-family: NanumSquareR;
  src: url('../assets/fonts/NanumSquareR.ttf')
    format('truetype');
}

@font-face {
  font-family: NanumSquareB;
  src: url('../assets/fonts/NanumSquareB.ttf')
    format('truetype');
}

input {
  all: unset;
  padding: 1% 2%;
  font-family: inherit;
  background-color: #f4f4f4;
  border-radius: 0;
}

input[type='submit'] {
  cursor: pointer;
}

.hiddenFileBox {
  display: none;
}

textarea {
  all: unset;
  padding: 2%;
  background-color: #f4f4f4;
}

button {
  border: none;
}

@media screen and (min-width: 1200px) {
  html {
    font-size: 62.5% !important;
  }
}

@media screen and (min-width: 426px) and (max-width: 1199px) {
  html {
    font-size: 39% !important;
  }
}

/* @media screen and (min-width: 426px) and (max-width: 767px) { */
@media screen and (min-width: 426px) and (max-width: 900px) {
  html {
    /* font-size: 25%; */
    font-size: 4px !important;
  }
}

@media screen and (max-width: 425px) {
  html {
    /* font-size: 13.8%; */
    font-size: 2.208px !important;
  }
}

/* @media screen and (max-width: 425px) {
    .container {
      padding-left: 12rem;
    }
  
    .checkmark {
      width: 8rem;
      height: 8rem;
    }
  
    .container .checkmark::after {
      top: 0.5rem;
      left: 1.5rem;
      width: 2rem;
      height: 3rem;
      border: solid white;
      border-width: 0 1rem 1rem 0;
      transform: rotate(45deg);
    }
  
    .checkmark_radio {
      width: 8rem;
      height: 8rem;
    }
  
    .container .checkmark_radio::after {
      top: 2rem;
      left: 2rem;
      width: 3rem;
      height: 3rem;
    }
  } */
