@use '../../styles/constants/colors';
@use '../../styles/constants/fonts';

.paginationWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  // padding-bottom: 10rem;
  margin: 0 auto;
  font-family: fonts.$LIGHT;

  button {
    font-size: 2rem;
    cursor: pointer;
  }

  .blackBox {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0 0.8rem;
    font-size: 1.5rem;
    color: colors.$WHITE;
    text-align: center;
    background-color: colors.$BLACK;
  }

  .pageNumber {
    padding: 0 1rem;
    background: colors.$WHITE;
    &:hover {
      text-decoration: underline;
    }
  }

  .active {
    color: colors.$RED;
  }
}
@media screen and (max-width: 425px) {
  .paginationWrapper {
    .blackBox {
      width: 9.5rem;
      height: 9.5rem;
      font-size: 6rem;
    }

    .pageNumber {
      padding: 0 5rem;
      font-size: 8rem;
    }
  }
}
