@use '../../styles/constants/colors';
@use '../../styles/constants/fonts';

.mainUp {
  // background-image: url();
  background-image: url('../../assets/images/main.png');
  // height: 70rem;
}

.secondUp {
  // background-image: url();
  background-image: url('../../assets/images/bg2.png');
  // height: 70rem;
}

.hedaerBox:hover,
.hedaerBox:hover .dropdown {
  background-color: #ededed;
}

.main {
  display: flex;
  padding: 0 320px 0 320px;
}

.leftContainer {
  position: relative;
  display: inline-block;
}

.sideHeader {
  width: 290px;
  height: 420px;
  background: colors.$RED;
}

.headText1 {
  margin: 0 18px 2px 0;
  padding-left: 17.8px;
  padding-top: 325.5px;
  color: colors.$WHITE;
  font-family: fonts.$GMARKET_TTF;
  font-size: 40px;
  letter-spacing: -2.2px;
  line-height: 1.5;
}

.headText2 {
  margin: 2px 0 0 1px;
  padding-bottom: 20.5px;
  color: colors.$WHITE;
  font-family: fonts.$GMARKET_TTF;
  font-size: 14px;
  padding-left: 20px;
  letter-spacing: -0.63px;
}

.sideMain {
  width: 290px;
  height: 654px;
}

.sideFooter {
  width: 290px;
  height: 117px;
  bottom: 0;
  background: colors.$RED;
}

.footText {
  color: colors.$WHITE;
  font-family: fonts.$GMARKET_TTF;
  font-size: 17px;
  display: table-cell;
  padding-top: 15px;
  text-align: left;
  line-height: 1.22;
  padding-left: 20px;
}

.footText > a {
  margin-left: 170px;
  float: right;
}

.footText > a .arrowRightIcon {
  color: white;
}

.rightContainer {
  position: relative;
  display: block;
  padding-left: 103px;
}

/* 모바일 */
.mainVisual {
  // width: 100%;
  position: relative;
}

.btnWrapp {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding-bottom: 5.2rem;

  .topBtn {
    all: unset;
    float: right;
    padding: 1.2rem;
    border-radius: 50%;
    font-size: 17px;
    line-height: 1.8;
    text-align: center;
    background-color: colors.$RED;
    color: colors.$WHITE;
    font-family: fonts.$NANUMS_B;
  }
}

@media screen and (max-width: 425px) {
  .mobileOff {
    display: none;
  }

  .mainUp,
  .secondUp {
    margin-top: 25rem;
    //   background-image: none;
  }
}

@media screen and (max-width: 1670px) {
  .main {
    padding: 0 250px 0 250px;
  }
}

@media screen and (max-width: 1615px) {
  .main {
    padding: 0 200px 0 200px;
  }
}

@media screen and (max-width: 1500px) {
  .main {
    padding: 0 100px 0 100px;
  }
}

@media screen and (max-width: 1400px) {
  .main {
    padding: 0;
  }
}

@media screen and (max-width: 1300px) {
  .main {
    padding: 0 100px 0 100px;
  }
}

@media screen and (max-width: 1170px) {
  .main {
    padding: 0;
  }
}
