@use '../../../styles/constants/colors';
@use '../../../styles/constants/fonts';

.main > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #333333;
  > p {
    font-family: fonts.$NANUMS_L;
    color: colors.$WHITE;
  }
  .mainTitle {
    font-size: 5rem;
    margin-top: 15rem;
    margin-bottom: 3.3rem;
    letter-spacing: -2.75px;
    > span {
      color: colors.$RED;
    }
  }

  .subTitle {
    font-size: 1.5rem;
    // max-width: 67rem;
    max-width: 77rem;
    line-height: 2rem;
    text-align: center;
  }
}
@media screen and (max-width: 425px) {
  .mobileOff {
    display: none;
  }
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    height: auto;
  }
}
