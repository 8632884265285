@use '../../styles/constants/colors';
@use '../../styles/constants/fonts';

.footerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  // border-top: solid 1px rgb(3, 3, 3);
  margin-right: 5%;
  margin-left: 5%;
  // padding: 3% 2%;
  padding-bottom: 40rem;
  padding-top: 5%;
}

.socialWrapper {
  display: flex;
  align-items: center;
  padding-right: 75%;

  > li {
    float: left;
    padding-right: 0.5rem;
    line-height: 7;

    > a > img {
      width: 80%;
    }
  }
}

.contactWrapper {
  width: 100%;
  margin-bottom: 3rem;

  > li {
    list-style: none;
    color: #888;
    font-size: 15px;
    line-height: 1.92;

    > span {
      font-size: 17px;
      color: colors.$BLACK;
      font-weight: bold;
      padding-right: 0.2rem;
    }
  }
}

.lawsdaqBtnWrapper {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button {
    width: 100%;
    // padding: 1.8rem 0 1.8rem 0;
    // padding: 5% 0;
    height: 28rem;
    background-color: colors.$BLACK;
    text-align: center;

    > p {
      font-family: fonts.$NANUMS_B;
      color: colors.$WHITE;
      font-size: 20px;
      // line-height: 1.2;
    }
  }
}

@media screen and (min-width: 426px) and (max-width: 900px) {
  .lawsdaqBtnWrapper {
    > button {
      height: 17rem;
      > p {
      }
    }
  }
}
